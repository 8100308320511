export enum PanelName {
  ADD_FORM = 'addFormPanel',
  FORM_MANAGE_FIELDS = 'manageFieldsPanel',
  FORM_SETTINGS = 'settingsPanel',
  NEW_FORM_SETTINGS = 'formSettingsPanel',
  FORM_STYLE = 'formStylePanel',
  FORM_LAYOUT = 'formLayoutPanel',
  FIELD_SETTINGS = 'fieldSettingsPanel',
  SUBMIT_SETTINGS = 'submitSettingsPanel',
  ADD_FIELD = 'addFieldPanel',
  UPGRADE_ALERT = 'upgradeAlertPanel',
  FORM_MANAGE_SUBSCRIBERS = 'manageSubscribersPanel',
  FIRST_TIME = 'firstTimePanel',
  SITE_MEMBERS_SETTINGS = 'panels.focusPanels.siteMembersSettingsPanel',
  CONNECT_FIELD = 'connectFieldPanel'
}
