import 'isomorphic-fetch'
import Experiments from '@wix/wix-experiments'
import { FIELDS_ROLES, ROLE_DOWNLOAD_MESSAGE, ROLE_FORM, ROLE_MESSAGE } from '../constants/roles'
import { initBiLogger } from '../utils/bi'
import { getAppVersion, getSentryDSN, withRetries, serializeError } from '../utils/utils'
import { EVENTS } from '../constants/bi'
import * as _ from 'lodash'
import * as Raven from 'raven-js'
import {
  escapeRegExp,
  innerText,
  isUploadButton,
  isCaptchaField,
  addContactsValidation,
} from './viewer-utils'
import { createWixData } from './wix-data'
import { strategies } from './strategy/strategies'
import {
  DEFAULT_SUBMIT_ERROR,
  ERROR_TYPE_TAG_KEY,
  getAttachments,
  getCollectionFields,
  getFields,
  getSubmitButton,
  resetFields,
  sendFieldsToServer,
  sendFieldsToWixData,
  sendWixAnalytics,
  validateFields,
} from './submit-utils'
import { getValidCollectionId } from '../editor-app/core/utils'
import { FormsFieldPreset } from '../constants/field-types'

const ERROR_COLOR = '#FF4040'
const ERRORS = {
  FILE_UPLOAD: 'File could not be uploaded. Try again or choose a different file.',
  SUBMISSION:
    'Sorry, something went wrong and the form was not submitted. Try again now or later if the problem persists.',
}

const viewerEvents = EVENTS.VIEWER_APP

Raven.config(getSentryDSN(), {
  logger: 'logger-viewer-app',
  release: getAppVersion(),
})

let initInstance
let biLogger: any = {}
let linksUtil
let wixData
let viewerAppUrl
let resolveExperiments
const experimentsPromise: Promise<Experiments> = new Promise(resolve => {
  resolveExperiments = resolve
})
const getExperiments = () => experimentsPromise

const getErrorModalUrl = msg =>
  viewerAppUrl &&
  viewerAppUrl
    .split('/')
    .slice(0, -1)
    .concat(['statics', `viewer-modal-panel.html?msg=${msg}`])
    .join('/')

const displayErrorModal = async ({ msg, wixWindow, width = 445, height = 250 }) => {
  const experiments = await getExperiments()
  if (experiments.enabled('specs.cx.FormBuilderServerErrorModal')) {
    wixWindow.openModal(getErrorModalUrl(msg), { width, height })
  }
}

export const initAppForPage = ({ instance, url }, { links }) => {
  linksUtil = links
  initInstance = instance
  viewerAppUrl = url

  wixData = createWixData(self.elementorySupport)

  resolveExperiments(new Experiments({ baseUrl: 'https://www.wix.com', scope: 'wix-form-builder' }))
  return Promise.resolve()
}

const parseInstance = instance => JSON.parse(atob(instance.split('.')[1]))

const getVisitorId = () => initInstance && parseInstance(initInstance).aid

const getMsid = () => initInstance && parseInstance(initInstance).metaSiteId

const getFormName = $w => {
  const form = $w(`@${ROLE_FORM}`)
  return {
    form_comp_id: form.uniqueId,
    form_name: form.connectionConfig.formName,
  }
}

const getFormParamsForBi = ($w, fields, wixLocation) => ({
  visitor_id: getVisitorId(),
  num_of_attachments: getAttachmentsCount(fields),
  form_url: wixLocation.url || '',
  ...getFormName($w),
})

const getSubmitErrorParamsForBi = ({ $w, fields, wixLocation, reason, reason_body }) => ({
  reason,
  reason_body,
  ...getFormParamsForBi($w, fields, wixLocation),
})

const getAttachmentsCount = fields =>
  _.filter(fields, field => isUploadButton(field) && field.value.length > 0).length

const getFieldValidity = fields => {
  const valueMissing = 'valueMissing'
  const errorOrder = [
    valueMissing,
    'fileNotUploaded',
    'typeMismatch',
    'patternMismatch',
    'rangeOverflow',
    'rangeUnderflow',
    'stepMismatch',
    'tooLong',
    'tooShort',
    'badInput',
    'customError',
  ]
  let errorType = _.find(errorOrder, error => _.some(fields, `validity.${error}`))
  const field = _.find(fields, field => {
    if (isCaptchaField(field)) {
      const missingToken = _.isEmpty(field.token)

      if (missingToken) {
        errorType = valueMissing
      }

      return missingToken
    }

    return _.get(field, `validity.${errorType}`)
  })

  return `${errorType} : ${_.get(field, 'connectionConfig.fieldType')}`
}

const isTemplate = wixLocation => !wixLocation.baseUrl

const showFormError = (message, errorMessage) => {
  if (!_.get(message, 'html')) {
    return
  }
  const colorRegExp = /color: ?[^;"]+/
  let htmlErrorMessage = errorMessage
  if (message.html.indexOf(colorRegExp) === -1) {
    htmlErrorMessage = `<span style="color: ${ERROR_COLOR}">${htmlErrorMessage}</span>`
  }
  message.html = message.html
    .replace(colorRegExp, `color: ${ERROR_COLOR}`)
    .replace(new RegExp(`>${escapeRegExp(innerText(message.html))}`), `>${htmlErrorMessage}`)
  message.show()
}

const onSubmit = async (
  { $w, collectionId, $message = {}, wixLocation, wixWindow, wixSite, wixPay },
  strategy
) => {
  let fields = []
  let $submitButton
  let experiments = {
    enabled: (_key: string) => false,
  }

  try {
    experiments = await getExperiments()
  } catch {}

  const postSubmitActions = (shouldShowSubmissionSuccess = true) => {
    if (shouldShowSubmissionSuccess) {
      resetFields(fields)
      strategy.postSubmission()
    }

    if (experiments.enabled('specs.cx.FormBuilderSendAnalytics') && !isTemplate(wixLocation)) {
      sendWixAnalytics({ wixSite, wixLocation, wixWindow })
    }
  }

  try {
    biLogger.log({
      evid: viewerEvents.USER_CLICKS_SUBMIT,
      ...getFormParamsForBi($w, fields, wixLocation),
    })

    $submitButton = getSubmitButton($w)
    $submitButton.disable()

    fields = getFields({ $w, roles: FIELDS_ROLES })
    if (!validateFields({ fields, strategy })) {
      biLogger.log({
        evid: viewerEvents.SUBMISSION_FAILURE,
        ...getSubmitErrorParamsForBi({
          $w,
          fields,
          wixLocation,
          reason: 'field validity',
          reason_body: getFieldValidity(fields),
        }),
      })

      $submitButton.enable()

      return false
    }

    let attachments, serverRequest

    if (!isTemplate(wixLocation)) {
      attachments = await getAttachments(fields)
      serverRequest = await sendFieldsToServer({
        strategy,
        attachments,
        fields,
        experiments,
        viewMode: wixWindow.viewMode,
      })
    } else {
      postSubmitActions()
      $submitButton.enable()
      return true
    }

    if (serverRequest && serverRequest.ok) {
      if (collectionId) {
        const fieldsToInsert = getCollectionFields({ fields, attachments })

        if (experiments.enabled('specs.cx.FormBuilderSendToWixDataWithRetries')) {
          const sendFieldsToWixDataWithRetries = withRetries({
            attempt: sendFieldsToWixData,
          })
          await sendFieldsToWixDataWithRetries({ wixData, collectionId, fieldsToInsert })
        } else {
          await sendFieldsToWixData({ wixData, collectionId, fieldsToInsert })
        }

        biLogger.log({
          evid: viewerEvents.SENT_TO_WIXDATA_SERVER_SUCCESS,
          ...getFormParamsForBi($w, fields, wixLocation),
        })
      }

      let shouldShowSuccessMessage = true

      if (experiments.enabled('specs.cx.FormBuilderShowPaymentForm')) {
        const serverResponse = await serverRequest.json()
        if (serverResponse.orderId) {
          const userInfo = getUserInfo(fields)
          const paymentResponse = await wixPay.startPayment(serverResponse.orderId, { userInfo })
          if (paymentResponse.status !== 'Successful') {
            shouldShowSuccessMessage = false
          }
        }
      }

      // this event should be after all server requests (wix forms + wix data)
      biLogger.log({
        evid: viewerEvents.SUBMISSION_SUCCESS,
        ...getFormParamsForBi($w, fields, wixLocation),
      })

      postSubmitActions(shouldShowSuccessMessage)
    } else {
      displayErrorModal({ wixWindow, msg: ERRORS.SUBMISSION })

      biLogger.log({
        evid: viewerEvents.SUBMISSION_FAILURE,
        ...getSubmitErrorParamsForBi({
          $w,
          fields,
          wixLocation,
          reason: 'server error',
          reason_body: _.get(serverRequest, 'status'),
        }),
      })
    }

    $submitButton.enable()
  } catch (err) {
    if ($submitButton) {
      $submitButton.enable()
    }

    const context = Raven.getContext()
    const reason = _.get(context, ['tags', ERROR_TYPE_TAG_KEY]) || DEFAULT_SUBMIT_ERROR

    biLogger.log({
      evid: viewerEvents.SUBMISSION_FAILURE,
      ...getSubmitErrorParamsForBi({
        $w,
        fields,
        wixLocation,
        reason,
        reason_body: err.name,
      }),
    })

    console.error(`form submit failed with: ${err}`) //eslint-disable-line no-console

    showFormError($message, `Something went wrong. Please try again later`) // FIXME - eager i18n for error message

    Raven.captureException(err, {
      extra: {
        error: serializeError(err),
      },
    })
  }
}

const getUserInfo = fields => {
  const wantedFieldTypes = [
    FormsFieldPreset.FIRST_NAME,
    FormsFieldPreset.LAST_NAME,
    FormsFieldPreset.PHONE,
    FormsFieldPreset.EMAIL,
  ]
  const userInfo = {}

  fields.forEach(field => {
    const {
      connectionConfig: { fieldType },
    } = field
    if (!_.isEmpty(field.value) && wantedFieldTypes.includes(fieldType)) {
      userInfo[fieldType] = field.value
    }
  })

  return userInfo
}

const registerSubmitButtonIfExists = ($w, submitArgs) => {
  const $submitButton = getSubmitButton($w, false)

  const strategy = _.find(strategies, s => s.isEnabled($w))
  if (!$submitButton || !strategy) {
    return
  }
  const strategyImp = new strategy(submitArgs, initInstance, linksUtil)
  $submitButton.onClick(Raven.wrap(() => onSubmit(submitArgs, strategyImp)))
  // FIXME - Check why Raven.wrap does not catch exception (replaced throw err with captureException to overcome this for now)
}

const pageReadyImpl = ($w, payload) => {
  const {
    window: wixWindow,
    location: wixLocation,
    user: wixUsers,
    site: wixSite,
    pay: wixPay,
  } = payload

  try {
    Raven.setUserContext({ id: wixLocation.url })
    Raven.setExtraContext({ instance: initInstance })
  } catch (err) {
    Raven.captureException(err)
  }
  if (!$w(`@${ROLE_FORM}`).length) {
    return
  }
  biLogger = initBiLogger({ defaults: { msid: getMsid() } })
  const form = $w(`@${ROLE_FORM}`)
  const {
    collectionId,
    secondsToResetForm,
    successActionType,
    successLinkValue,
    successExternalLinkValue,
    submitOptionsUploadedObject,
  } = form.connectionConfig

  const preset = _.get(form.connectionConfig, 'preset')

  if (preset) {
    Raven.setTagsContext({ preset })
  }

  const plugin = _.chain(form.connectionConfig)
    .get('plugins')
    .first()
    .get('id')
    .value()

  if (plugin) {
    Raven.setTagsContext({ plugin })
  }

  const formId = form.uniqueId
  const validCollectionId = getValidCollectionId(formId, collectionId)
  const $successMessage: any = $w(`@${ROLE_MESSAGE}`)
  const $downloadMessage: any = $w(`@${ROLE_DOWNLOAD_MESSAGE}`)
  let submitArgs: any = {
    $w,
    collectionId: validCollectionId,
    secondsToResetForm,
    successActionType,
    successLinkValue,
    successExternalLinkValue,
    submitOptionsUploadedObject,
    wixLocation,
    wixWindow,
    wixUsers,
    wixSite,
    wixPay,
  }

  if (_.get($successMessage, 'hide')) {
    $successMessage.hide()
    submitArgs = {
      ...submitArgs,
      $message: $successMessage,
      messageText: _.get($successMessage, 'html'),
    }
  }
  if (_.get($downloadMessage, 'hide')) {
    $downloadMessage.hide()
    submitArgs = {
      ...submitArgs,
      $message: $downloadMessage,
      messageText: _.get($downloadMessage, 'html'),
    }
  }
  registerSubmitButtonIfExists($w, submitArgs)
  addContactsValidation($w)
}

export const createControllers = Raven.wrap(controllerConfigs => {
  return controllerConfigs.map(() =>
    Promise.resolve({
      pageReady: Raven.wrap(pageReadyImpl),
    })
  )
})
