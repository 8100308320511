import { ROLE_FORM, ROLE_SUBMIT_BUTTON, FIELDS } from '../../constants/roles'
import * as _ from 'lodash'
import { SecondsToResetDefaults, SuccessActionTypes } from '../../constants/success-settings'
import {
  escapeRegExp,
  getFieldType,
  innerText,
  isRadioGroup,
  isUploadButton,
  toMiliseconds,
  isCaptchaField,
} from '../viewer-utils'
import { getInputValue } from '../input-value'
import { fetchRetry } from '../../utils/fetch-utils'
import { FormsFieldPreset } from '../../constants/field-types'
import { SubmitFormRequest, Field, EmailConfig } from '../../types/domain-types'
import { createFieldDto, getFieldValue } from '../field-dto/field-dto'
import { EMPTY_EMAIL_ID, isEmptyEmailId } from '../../utils/utils'
import { FORMS_APP_DEF_ID } from '../../constants'

// TODO: Cover this file (registration form) with tests

const SUBMIT_API_URL = '/_api/wix-form-builder-web/submit'

const getLabel = field => field.connectionConfig.crmLabel

const isSubscribeField = field =>
  field.connectionConfig.fieldType === FormsFieldPreset.GENERAL_SUBSCRIBE

const getCrmType = field => {
  if (isUploadButton(field)) {
    return 'attachment'
  }

  if (isSubscribeField(field)) {
    return 'subscribe'
  }

  return field.connectionConfig.crmType
}

const createField = (field, attachments) => ({
  label: getLabel(field),
  crmType: getCrmType(field),
  crmTag: field.connectionConfig.crmTag,
  customFieldId: field.connectionConfig.customFieldId,
  value: getInputValue(field, attachments),
  inputType: getFieldType(field),
})

const getBaseUrl = wixLocation => {
  const urlDirs = wixLocation.baseUrl.split('/')
  let baseUrl = urlDirs.slice(0, urlDirs.length - 1).join('/')
  if (baseUrl === 'https:/' || baseUrl === 'http:/') {
    baseUrl = wixLocation.baseUrl // TODO fix with amitay
  }

  return baseUrl
}

const getRecipients = (emailId, secondEmailId) => {
  let sendToOwner
  const emailIds = []

  if (_.isEmpty(emailId)) {
    // support backwards compatibility when emailId is empty string
    sendToOwner = true
  } else {
    if (emailId !== EMPTY_EMAIL_ID) {
      // send to other recipients
      emailIds.push(emailId)
    }
  }

  if (!isEmptyEmailId(secondEmailId)) {
    emailIds.push(secondEmailId)
  }

  return { sendToOwner, emailIds }
}

const createEmailConfig = (emailId, secondEmailId): EmailConfig => {
  const recipients = getRecipients(emailId, secondEmailId)
  const emailConfig: EmailConfig = { sendToEmails: { emailIds: [] } }

  if (recipients.sendToOwner) {
    emailConfig.sendToOwner = {} // equivalent to true in proto
  }

  emailConfig.sendToEmails.emailIds = [...recipients.emailIds]

  return emailConfig
}

const createFieldsDto = (fields, attachments) => {
  const fieldsDto = []
  fields.forEach(field => {
    const fieldDto: Field = createFieldDto(field, attachments)
    fieldsDto.push(fieldDto)
  })

  return fieldsDto
}

const EnrichPayloadWithCaptcha = ({$w, payload}) => {
  const captchaField = $w(`@${FIELDS.ROLE_FIELD_RECAPTCHA}`)

  if (captchaField.length > 0) {
    const value = getFieldValue(captchaField)
    payload.security = { captcha: value }
  }
}

const sendActivity = ($w, { attachments, fields, wixLocation, wixWindow, instance, shouldCheckForCaptchaField }) => {
  const baseUrl = getBaseUrl(wixLocation)
  const url = `${baseUrl}/_api/wix-forms/v1/submit-form`
  const headers = { Authorization: instance, 'Content-Type': 'application/json' }

  const form = $w(`@${ROLE_FORM}`)
  const { emailId, secondEmailId, labels, formName = '' } = form.connectionConfig

  const fieldsDto: Field[] = createFieldsDto(fields, attachments)
  const emailConfig: EmailConfig = createEmailConfig(emailId, secondEmailId)

  const payload: SubmitFormRequest = {
    formProperties: {
      formName,
      formId: form.uniqueId,
    },
    emailConfig,
    viewMode: wixWindow.viewMode,
    fields: fieldsDto,
    labelIds: labels,
  }

  if (shouldCheckForCaptchaField) {
    EnrichPayloadWithCaptcha({ $w, payload })
  }

  return fetchRetry(url, {
    method: 'POST',
    headers,
    mode: 'cors',
    body: JSON.stringify(payload)
  })
}

//tslint:disable-next-line
const sendActivityOld = ($w, { attachments, fields, wixLocation, wixWindow, instance }) => {
  const form = $w(`@${ROLE_FORM}`)
  let { emailId, secondEmailId, labels, formName = '', product } = form.connectionConfig

  emailId = emailId !== EMPTY_EMAIL_ID ? emailId : null
  secondEmailId = secondEmailId !== EMPTY_EMAIL_ID ? secondEmailId : null

  const config = { emailId, secondEmailId }
  const productId = _.get(product, 'id')
  if (productId) {
    config['productId'] = productId
  }

  const payload = {
    config,
    fields: _.map(fields, field => createField(field, attachments)),
    labels,
    attachments,
    formName,
    formId: form.uniqueId,
    viewMode: wixWindow.viewMode,
  }

  const method = 'post'
  const headers = { 'Content-Type': 'application/json' }
  const urlDirs = wixLocation.baseUrl.split('/')
  let baseUrl = urlDirs.slice(0, urlDirs.length - 1).join('/')
  if (baseUrl === 'https:/' || baseUrl === 'http:/') {
    baseUrl = wixLocation.baseUrl // TODO fix with amitay
  }
  return fetchRetry(`${baseUrl}${SUBMIT_API_URL}?instance=${instance}`, {
    method,
    headers,
    mode: 'cors',
    body: JSON.stringify(payload),
  })
}

const showSuccessMessageIfExists = (
  message,
  { messageText, secondsToResetForm, successActionType, successLinkValue },
  wixLocation,
  linksUtil
) => {
  switch (successActionType) {
    case SuccessActionTypes.LINK:
    case SuccessActionTypes.EXTERNAL_LINK:
      setTimeout(() => wixLocation.to(linksUtil.toUrl(successLinkValue)), 100)
      return Promise.resolve()

    case SuccessActionTypes.DOWNLOAD_DOCUMENT:
      if (_.get(message, 'html', undefined) === undefined) {
        return Promise.resolve()
      }
      const messageInnerText = escapeRegExp(innerText(message.html))
      message.html = messageText.replace(
        new RegExp(`>${messageInnerText}`),
        `><a href="${linksUtil.toUrl(successLinkValue)}" target="_blank">${messageInnerText}</a>`
      )
      message.show()
      return Promise.resolve()

    default:
      const hasMessageContent = _.get(message, 'html', undefined) !== undefined
      if (!hasMessageContent) {
        return Promise.resolve()
      }
      message.html = messageText
      message.show()
      return new Promise(resolve =>
        setTimeout(
          () => resolve(message.hide()),
          toMiliseconds(secondsToResetForm || SecondsToResetDefaults.MIN)
        )
      )
  }
}

export class FormStrategy {
  constructor(protected submitArgs, private initInstance, private linksUtil) {}

  static isEnabled($w) {
    return $w(`@${ROLE_SUBMIT_BUTTON}`)[0]
  }

  get instance() {
    return this.submitArgs.wixSite.getAppToken(FORMS_APP_DEF_ID)
  }

  validateFields(fields: any) {
    return _.filter(
      fields,
      field => !isUploadButton(field) || (field.required && field.value.length === 0)
    ).every(field => {
      if (isRadioGroup(field)) {
        // TODO - waiting for full fix for radioGroup
        return !field.required || field.value.length > 0
      }

      if (isCaptchaField(field)) {
        return !_.isEmpty(field.token)
      }

      if ('valid' in field) {
        return field.valid
      }
      return true
    })
  }

  async execute({
    attachments,
    fields,
    skipSendActivity = false,
    experiments,
  }): Promise<ServerResponse> {
    if (skipSendActivity) return Promise.resolve({ ok: true })

    const platformized = experiments.enabled('specs.cx.FormBuilderPlatformizedSubmit')
    const shouldCheckForCaptchaField = experiments.enabled('specs.cx.FormBuilderCaptchaField')

    const { $w, wixLocation, wixWindow } = this.submitArgs

    if (platformized) {
      return sendActivity($w, {
        attachments,
        fields,
        wixLocation,
        wixWindow,
        instance: this.instance,
        shouldCheckForCaptchaField
      })
    } else {
      return sendActivityOld($w, {
        attachments,
        fields,
        wixLocation,
        wixWindow,
        instance: this.initInstance,
      })
    }
  }

  async postSubmission() {
    const {
      $message,
      messageText,
      secondsToResetForm,
      successActionType,
      successLinkValue,
      wixLocation,
    } = this.submitArgs
    await showSuccessMessageIfExists(
      $message,
      {
        messageText,
        secondsToResetForm,
        successActionType,
        successLinkValue,
      },
      wixLocation,
      this.linksUtil
    )
  }
}
