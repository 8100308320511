import { PanelName } from '../editor-app/core/manage-panels/consts/panel-names'

export const BI = {
  ENDPOINT: 'form-builder',
  ERROR_ENDPOINT: 'form-builder',
  SRC: 5,
}

const TEMPLATE_PICKER_PANEL = {
  OPEN_PANEL: 910,
  SELECT_TEMPLATE: 911,
  CHOOSE_TEMPLATE: 912,
}
const MANAGE_FIELDS_PANEL = {
  OPEN_PANEL: 913,
  DELETE_FIELD: 914,
  DUPLICATE_FIELD: 932,
}

const EDIT_FIELD_PANEL = {
  OPEN_PANEL: 917,
  VALUE_UPDATED: 918,
  TOGGLE_REQUIRED_FIELD: 919,
  OPEN_CONNECT_FIELD_DROPDOWN: 920,
  SELECT_FIELD_TO_CONNECT: 921,
  ADD_RESTRICTED_KEYWORD: 870,
}

const CONNECT_FIELD_PANEL = {
  OPEN_PANEL: 945
}

const FORM_SETTINGS_PANEL = {
  OPEN_PANEL: 922,
  VALUE_UPDATED: 923,
  SECONDS_TO_RESET_UPDATED: 925,
  SUCCESS_ACTION_TYPE_SELECTED: 926,
  SUCCESS_LINK_SELECTED: 927,
  CREATE_SUBMISSIONS_TABLE: 928,
  SUBMISSIONS_TABLE_CREATED_SUCCESSFULLY: 929,
  VIEW_SUBMISSIONS_TABLE: 931,
  CLICK_UPLOAD_BUTTON: 938,
}

const FORM_LAYOUT_PANEL = {
  OPEN_PANEL: 924,
  CHANGE_LAYOUT: 924,
}

const ADD_FIELD_PANEL = {
  OPEN_PANEL: 915,
  SELECT_FIELD_TO_ADD: 916,
  ADD_FIELD_COMPLETE: 903,
}

const UPGRATE_ALERT_PANEL = {
  OPEN_PANEL: 934,
  ACTION_CLICK: 935,
}

const FORM_STYLE_PANEL = {
  CUSTOM_DESIGN_ACTION: 906,
}

const MANAGE_SUBSCRIBERS_PANEL = {
  OPEN_PANEL: 867,
  ACTION_CLICKED: 868,
}

const ALL_PANELS = {
  LOAD_FINISHED: 907,
}

const VIEWER_APP = {
  USER_CLICKS_SUBMIT: 900,
  SUBMISSION_SUCCESS: 901,
  SENT_TO_WIXDATA_SERVER_SUCCESS: 904,
  SUBMISSION_FAILURE: 902,
}

const EDITOR = {
  DELETE_SUBMISSION_BUTTON: 933,
  USER_SAVE_TEMPLATE_WITH_FORM: 936,
  PASTE_APP_WIDGET: 912,
  DELETE_FORM: 908,
  FETCH_FAILED: 939,
}

const PANELS = {
  [PanelName.ADD_FORM]: TEMPLATE_PICKER_PANEL,
  [PanelName.FORM_SETTINGS]: FORM_SETTINGS_PANEL,
  [PanelName.FORM_MANAGE_FIELDS]: MANAGE_FIELDS_PANEL,
  [PanelName.ADD_FIELD]: ADD_FIELD_PANEL,
  [PanelName.UPGRADE_ALERT]: UPGRATE_ALERT_PANEL,
  [PanelName.FIELD_SETTINGS]: EDIT_FIELD_PANEL,
  [PanelName.FORM_LAYOUT]: FORM_LAYOUT_PANEL,
  [PanelName.FORM_STYLE]: FORM_STYLE_PANEL,
  [PanelName.FORM_MANAGE_SUBSCRIBERS]: MANAGE_SUBSCRIBERS_PANEL,
  [PanelName.CONNECT_FIELD]: CONNECT_FIELD_PANEL,
  allPanels: ALL_PANELS,
}

export const EVENTS = {
  PANELS,
  VIEWER_APP,
  EDITOR,
}

export const ORIGINS = {
  APP_MARKET: 'appmarket',
  ADD_PANEL: 'addpanel',
  SIGNUP_SETTINGS: 'signup',
  GFPP: 'gfpp',
  MANAGE_FIELDS: 'manage fields',
  ADI_EDIT_FIELD: 'adi edit field',
}

export const TOGGLE_REQUIRED_ORIGINS = {
  CHECKED_BY_DEFAULT: 'checked by default',
  REQUIRED: 'required'
}

export const PANEL_NAMES = {
  formStylePanel: 'form design',
}

export const SETTINGS_PANEL_VALUE_UPDATED = {
  MESSAGE: 'success message',
  EMAIL: 'email'
}

export const FIELDS_PANEL_VALUE_UPDATED = {
  TITLE: 'title toggle',
}

export const BUILDER_ORIGINS = {
  EDITOR: 'Editor',
  ADI: 'ADI'
}
